import axios from "axios";
import { api } from "redux/_axios";

const logIn = (data: any) => axios.post("/api/login", data);
const register = (data: any) => axios.post("/api/register", data);
const logout = () => axios.post("/api/logout");
const refresh = () => axios.post("/api/refresh");
const forgotPasswordReq = (data: any) =>
  axios.post("/api/forgot-password", data);
const resetPassword = ({ token, data }: any) =>
  axios.post(`/api/forgot-password/reset/${token}`, data);
const verifyEmail = ({ verify_id, token_hash_sent_by_mail }: any) =>
  axios.get(`/api/email/verify/${verify_id}/${token_hash_sent_by_mail}`);

const UserAPI = {
  logIn,
  register,
  verifyEmail,
  logout,
  refresh,
  forgotPasswordReq,
  resetPassword,
};

export default UserAPI;
